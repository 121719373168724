/* LockUnlockReportSearch.css */

.main-child ul {
  list-style-type: none;
  padding: 0;
}

.main-child li {
  border: 1px solid #ddd;
  margin: 5px;
  padding: 5px;
  display: inline-flex;
  /* Use grid layout for better control */
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  /* Break into two rows with flexible width */
  grid-gap: 10px;
  /* Adjust spacing between fields */
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* ... other styles ... */

.report-item p {
  display: block;
  /* Display fields as blocks for full-width usage */
  margin-bottom: 5px;
  /* Adjust margin for spacing */
}

.report-item {
  background-color: #eaeaea;
  /* Use light gray color */
  border: 1px solid #ddd;
  /* Add black border */
  padding: 10px;
  /* Adjust padding for desired margin */
}

.main-child button {
  padding: 8px 15px;
  border: none;
  margin-right: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.main-child button:hover {
  background-color: #FF683A;
  color: #fff;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.main-child h3 {
  margin-bottom: 10px;
  color: #333;
}

/* Style for individual fields */
.main-child li div {
  flex: 1 1 50%;
  /* Adjust the width as needed */
  margin-bottom: 10px;
}

/* Optional: Add hover effect for each row */
.main-child li:hover {
  background-color: #e1f5fe;
  transition: background-color 0.3s;
}

.search-container {
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 1em;
  height: -webkit-fill-available;
  width: 25%;
}

.search-child {
  width: 100%;
}

.search-container label {
  font-weight: bold;
}

.search-container input {
  padding-block: 3px;
}

.central-section {
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  padding-inline: 15px;
  margin-right: 5px;
  flex-direction: column;
}

.main-child {
  display: flex;
  height: -webkit-fill-available;
  margin-bottom: 2.6em;
}
.search-container,
.search-container button,
.central-section, 
.export-div button {
  border: 1px solid rgba(122, 113, 113, 0.732);
}

.export-div {
  display: flex;
  flex-flow: row-reverse;
  margin-top: 18px;
  gap: 1em;
}

.search-container button,
.export-div button{
  color: #FFF;
  background-color: #313668;
  margin-right: 0px;
  margin-bottom: 18px;
  margin-top: 5px;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.5em;
}

.button-container .search-child {
  flex: 0 0 48%; /* Each button takes up 48% of the container width */
}


@media screen and (max-width: 768px) { /* Adjust breakpoint as needed */
  
.main-child {
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
}
}