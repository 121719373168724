.App {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.footer {
  color: rgb(189, 211, 211);
  font-size: small;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  background-color: #363166;
  text-align: center;
  height: 3%; /* Adjust height as needed */
  overflow-x: auto; /* Enable horizontal scrolling */
}

.header {
  position: fixed;
  top: 0;
  grid-row: span 1;
  background-color: #363166;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  width: 100%;
}




.footer a {
  color: rgb(189, 211, 211);
  margin-right: 10%;
  margin-left: 10%;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) { /* Adjust breakpoint as needed */
  .footer {
    text-align: center; /* Align links to the left */
    height: auto; /* Set height to auto for scrolling */
    gap: 1em;
  }

  .footer a {
    display: block; /* Stack links vertically */
    margin-right: 0; /* Remove horizontal margins */
    margin-bottom: 10px; /* Add vertical spacing between links */
  }
}


.yekkada-button:not([disabled]) {
  background: linear-gradient(0deg, rgba(255,191,233,1) 0%, rgba(255,255,255,1) 67%);
  color: #363166;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #222222;
}

.yekkada-button:not([disabled]):hover {
 background: #FFF;
  color: #363166;
  transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
  cursor: pointer;
}

.yekkada-button:not([disabled]):active {
  background: linear-gradient(0deg,  rgba(255,255,255,1) 7%, rgba(255,191,233,1) 80%);
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

/* Optional: Styles for disabled buttons */
.yekkada-button[disabled] {
  background: #EDE1F0;
  color: #7f7e8d;
  border-radius: 5px;
  padding: 5px;
  cursor: not-allowed;
  border: 1px solid #6a6767;
}


.modal {
  display: none; /* Initially hidden */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* Ensure it appears above other elements */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.modal-content {
  /* Style the content area as needed */
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.close-button {
  /* Style the close button as needed */
  position: absolute;
  top: 10px;
  right: 10px;
}

.icon-button{
  border: none;
  background: none;
}

.section-title {
  font-weight: bold;
  padding-bottom: 5px; /* Add space below the title */
  border-bottom: 1px solid #ddd; /* Add a separator line */
  margin-bottom: 15px; /* Adjust margin as needed */
}


.section-title {
  font-weight: bold;
  color: #333; /* Adjust text color as desired */
  position: relative; /* Allows positioning of accent */
}

.section-title::after {
  content: "";
  position: absolute; /* Position the accent element */
  bottom: -2px; /* Place it below the text */
  left: 0; /* Align it to the left of the title */
  width: 20px; /* Adjust width as needed */
  height: 2px; /* Adjust height as needed */
  background-color: #363166; /* Adjust accent color */
}

.filter-selector {
  font-weight: bolder;
  color: #363166; /* Adjust text color as desired */
  position: relative; /* Allows positioning of accent */
  font-size: xx-small;
}

.main-title {
  font-size: large;
  color: #333; /* Adjust text color as desired */
  position: relative; /* Allows positioning of accent */
}

.main-title::after {
  content: "";
  position: absolute; /* Position the accent element */
  bottom: -2px; /* Place it below the text */
  left: 0; /* Align it to the left of the title */
  width: 20px; /* Adjust width as needed */
  height: 2px; /* Adjust height as needed */
  background-color: #007bff; /* Adjust accent color */
}

.wrap-header-text {
  white-space: unset;
  word-wrap: break-word;
}