.login-container {
  text-align: center;
  height: 100%;
  background-image: url("../assets/main-background.jpg");
  background-position: top center;
  background-attachment: fixed;
}

.login-page {
  width: 25%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(146, 142, 142);
  background-color: #fff;
  box-shadow: 7px 7px 3px rgb(211, 205, 205);
  position: relative;
  left: 34%;
  top: 25%;
}

.login-page-icon {
  scale: 0.45;
}

/* Mobile CSS */

@media (max-width: 767px) {
  .login-page {
    width: 63%;
    left: 15%
  }
}

.login-page-element {
  padding: 10px;
  margin-bottom: 10px;
  display: block;
  font-weight: 500;
  border: 1px solid rgb(146, 142, 142);
}

.login-page button {
  background: #363166;
  color: #fff;
}

.login-page button:hover {
  background: #FF683A;
  color: #fff;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.login-page button:active {
  background: #363166;
  color: #fff;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.errMsg {
  font-size: small;
  color: red;
  font-weight: 400;
}

.login-page-icon {
  width: 40%;
  margin-left: 25%;
  margin-bottom: 5px;
  scale: 1.0;
}