.under-development {
    background-color: #f5f5f5;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    text-align: center;
  }
  
  .under-development h2 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .under-development p {
    font-size: 0.9em;
    margin-bottom: 15px;
  }
  
  .under-development img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto;
  }
  