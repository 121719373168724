.mapping-table {
    border: 1px solid #ddd;
  }
  
  .mapping-table th {
    background-color: #ff683a;
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
  }
  
  .mapping-table td {
    font-size: 12px;
    color: #555;
    border-bottom: 1px solid #ddd;
  }
 
  .mapping-menu li{
    font-size: 12px !important;
  }
  
  .mapping-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .mapping-table td:last-child {
    text-align: left;
  }
  