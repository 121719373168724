body {
  /* Increase specificity to avoid conflicts (optional) */
  font-size: small;
  line-height: 1.47059;
  font-weight: 400;
  letter-spacing: -.022em;

  /* Use Roboto as the primary font family */
  font-family: Roboto, sans-serif;

  /* Fallback fonts for broader compatibility
  font-family: Arial, sans-serif; */
  color: #1d1d1f;
  font-style: normal;
  background: #fff;
  min-width: 320px;
  /* margin: 0; */
  /* padding: 0; */
  display: grid;
  height: 100%;
}

html {
  -webkit-text-size-adjust: 100%;
  quotes: "“" "”";
  height: 100%;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}