.custom-table,
.dynamic-table {
  border-collapse: collapse;
  box-shadow: 0 2px 8px rgba(0, 10, 10, 0.3);
}

.dynamic-table,
.custom-table,
.custom-table tr {
  border: 1px solid #dddddd;
  padding: 5px;
}

.custom-table td,
.custom-table th,
.dynamic-table td,
.dynamic-table th {
  padding-left: 10px;
  text-align: left;
  font-size: small;
  font-family: 14px/18px "Lucida Grande", "Lucida Sans Unicode", Helvetica, Arial, Verdana, sans-serif;
  
}


.dynamic-table th,
.custom-table th {
  background-color: #FF683A;
  color: #FFF;
  line-height: 1.47059;
  padding-right:5px ;
  font-weight: 400;
  letter-spacing: -.022em;
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}

.custom-table th:hover {
  cursor: default;
}

.custom-table tr:hover {
  background-color: #e0e0e0;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}


.custom-table tr:hover {
  cursor: pointer;
}

.hoverPointer:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: rgb(209, 156, 8);
}

.dynamic-table td {
  padding: 10px;
}


#refreshLocationData:hover {
  cursor: pointer;
  background-color: #e0e0e0;
  align-items: stretch;
  padding: 2px;
}

.dynamic-table,
.custom-table {
  width: 100%;
}

.custom-table .selected-row {
  /* background: linear-gradient(-5deg, rgba(255, 255, 255, 1) 18%,  rgb(235, 199, 237) 70%); */
background: linear-gradient(-20deg,  rgba(255,255,255,1) 5%, rgba(191,219,255,1) 83%);
  color: #363166;
}