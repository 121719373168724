.screen-layout {
    display: flex;
    flex-wrap: inherit;
    /* Allow items to wrap to the next line if necessary */
    gap: 6px;
    /* Shorthand for column-gap and row-gap */
    width: 100%;
    /* Use full width of the viewport */
    height: calc(100vh - 80px);
    /* Use 100% of the viewport height minus 80px for the top margin */
    overflow: hidden;
}

@media screen and (max-width: 768px) {
    .screen-layout {
        height: calc(100vh - 60px);
        /* Adjusted height for smaller screens */
        margin-top: 60px;
        /* Adjusted top margin for smaller screens */
    }
}

@media screen and (max-width: 480px) {
    .screen-layout {
        height: calc(100vh - 40px);
        /* Adjusted height for even smaller screens */
        margin-top: 40px;
        /* Adjusted top margin for even smaller screens */
    }
}


.grid-div {
    border: 1px solid rgba(122, 113, 113, 0.732);
}

.grid-div li {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.grid-div li .vert-menu-icon {
    margin-bottom: 5px;
}

.header {
    background-color: #363166;
    align-items: flex-end;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}

.company-logo {
    scale: 75%;
    box-shadow: 8px 6px 5px #ff683a;
    border: 1px solid rgb(8, 2, 89);
    margin-right: 5%;
    position: fixed;
}

.col-vertical-menu {
    padding: 10px;
}


.col-vertical-menu div {
    padding: 10px;
    margin: 10px;
    border: 1px solid;
    overflow-y: auto;
}

.col-central-content {
    padding: 20px;
    width: -webkit-fill-available;
}

.col-central-content>p {
    margin-left: 5px;
    font-size: medium;
    margin-top: -11px;
}

.col-right-details {
    padding-inline: 1em;
    min-width: fit-content;
    overflow-y: auto;
}

.col-right-details>table {
    margin-top: 6.5%;
}

.vert-menu-icon {
    scale: 1.5;
    color: #ff683a;
}

.vert-menu-icon:hover {
    color: #363166;
    cursor: pointer;
}

.col-vertical-menu>li {
    list-style: none;
    margin: 25%;
    padding: 5%;
}

.col-vertical-menu>li>span {
    font-size: x-small;
    font-weight: 600;
    margin-left: -5px;

}

.col-vertical-menu .selected .vert-menu-icon {
    color: #363166;
}

.asset-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.asset-card {
    display: flex;
    flex-direction: row;
    flex: content;
    gap: 20px;
    margin: 10px;
    padding: 2px;
    border: 1px solid rgb(187, 183, 183);
    border-radius: 5px;
}

.asset-card h3 {
    font-size: 24px;
    margin-bottom: 5px;
}

.asset-card p {
    font-size: 16px;
    margin-bottom: 5px;
}

.user-name-span {
    position: relative;
    align-self: flex-start;
    color: #fff;
    padding: 8px;
    margin-left: 10px;
}

.cursor-manager:hover {
    cursor: pointer;
}


.main-section-div {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
}

.report-nav-bar {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    padding-block: 0.25em;
}

.report-nav-bar button {
    background: #FFF;
    border: 1px solid #cb8383;
    border-radius: 2.5px;
    padding: 5px;
    font-size: small;

}

.report-nav-bar button:hover {
    cursor: pointer;
    box-shadow: 5px 5px 2px #ff683a;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}


.report-nav-bar>.selected {
    background: #ff683a;
    box-shadow: 5px 5px 2px rgb(211, 205, 205);
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
    color: #FFF;
}


.notification-div {
    margin-left: -8px;
}

.notification-icon {
    display: inline-block;
    padding: 3px;
}

.notification-icon:hover {
    transform: scale(1.2);
    /* Slightly increase the size on hover */
    transition: transform 0.25s ease;
    /* Add a smooth transition effect */
    filter: brightness(160%);
    /* Increase brightness for color change */
}

.notification-count {
    min-width: 31px;
    min-height: 3px;
    border-radius: 50%;
    background-color: #FFF;
    padding: 4px;
    color: #363168;
    font-size: x-small;
    text-align: center;
    z-index: 1;
    border: 1px solid #ff683a;
    box-shadow: 0 5px 8px rgba(0, 10, 10, 0.3);
    font-weight: 500;
    position: relative;
    top: -13px;
    right: 2px;
}


.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent gray background */
    z-index: 9998;
    /* Ensure the overlay is behind the dialog */
}

.popup-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    width: 80%;
    max-width: 600px;
    height: 70%;
    max-height: 80%;
    overflow: hidden;
    /* Ensure overflow:hidden for correct scrollbar behavior */
    display: flex;
    flex-direction: column;
    /* Content should be stacked vertically */
}

.popup-content {
    flex: 1;
    /* Allow content to expand to fill available space */
    overflow-y: auto;
    /* Enable vertical scrolling if content overflows */
    margin-top: 20px;
    border: 1px solid #ccc;
    padding: 7px;
}

.popup-buttons {
    margin-top: 20px;
    align-self: flex-end;
    /* Align buttons to the end of the container */
}

.yekkada-btn:not([disabled]),
.popup-buttons button {
    background-color: #363168;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
}

.yekkada-btn[disabled],
.popup-buttons button[disabled] {
    cursor: not-allowed;
    background-color: #CDCDCD;
}

.yekkada-btn:not([disabled]),
.popup-buttons button:not([disabled]) {
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.yekkada-btn:not([disabled]):hover,
.popup-buttons button:not([disabled]):hover {
    background-color: #ff683a;
}

.yekkada-btn[disabled]:hover,
.popup-buttons button[disabled] {
    cursor: not-allowed;
    background-color: #DDD;
}


.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    border: 1px solid #ccc;
    /* Light gray border */
    padding-inline: 5px;
    /* Add some padding for space around the icon */
}

.close-button:hover {
    background-color: #f0f0f0;
    /* Light gray background on hover */
}

.notification-record {
    border: 1px solid #ccc;
    box-shadow: 0 2px 8px #ff683a;
    padding-inline: 18px;
    margin-bottom: 10px;
}

.notification-record p {
    word-wrap: break-word;
    /* To ensure text wraps within the max width */
}

/* Style for the container */
.container {
    display: flex;
    padding-top: 2.5em;
}

/* Style for the form rows */
.form-row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1.5em;
}

/* Style for the form columns */
.form-column {
    flex: 2;
    margin-right: 10px;
    border: 1px solid rgba(122, 113, 113, 0.732);
    padding: 1em;
    border-radius: 2px;
    position: relative;
}

.form-column .form-group {
    display: inline-grid;
}

.form-column .form-group>span {
    margin-top: -11px;
    margin-left: 15px;
}


/* Style for the text fields */
.yekkada-input,
.container input[type="text"],
.container input[type="tel"],
.container input[type="password"],
.container input[type="email"],
.container select {
    padding: 10px;
    border: 1px solid #999;
    border-radius: 5px;
    margin: 1em;
    box-sizing: border-box;
}

/* Style for the placeholder */
::placeholder {
    color: #aaa;
}

.align-right {
    position: relative;
    align-self: end;
    padding-right: 10px
}

.align-center {
    position: relative;
    align-self: center;
    padding-right: 10px
}

.error-message {
    color: red;
    font-size: 12px;
}

.loading-banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.359);
    /* Semi-transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-banner p {
    font-size: 24px;
    font-weight: bold;
}

#fullName,
#email {
    width: 265px;
}

.register-btn-div {
    gap: 0.8em;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 15px;
}

.scrollable-y {
    overflow-y: auto;
}

.top-right {
    position: absolute;
    /* Position the button relative to its containing div */
    top: 0;
    /* Align the button to the top of the container */
    right: 0;
    /* Align the button to the right of the container */
    margin-right: 8px;
    margin-top: 5px;
    color: #fff;
    /* Example text color */
    border: none;
    /* Remove border */
    cursor: pointer;
    /* Change cursor to pointer on hover */
}

.button-container{
    margin-top: 8px;
}

.padded-container{
    padding-top: 2.5em;
}

.description {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .info-icon {
    color: #363168;
    font-size: 20px;
    margin-right: 10px;
  }
  
  p {
    font-size: 12px;
    line-height: 1.5;
    color: #333;
  }