.card {
    border: 1px solid #86709b; /* Light gray border */
    display: flex;
    gap: 0.5em;
    align-items: center;
  }
  
  .card input:hover,
  .card:hover {
    cursor: pointer;
    background-color:  rgba(183, 224, 223, 0.529);; 
  }
  
  .card-list {
    background: #f5f5f5; /* Very light gray background */
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 10px;
    max-height: 250px;
    overflow-y: auto;
    border: 1px solid #89848e;
  }
  
  .asset-id,
  .primary-id 
  {
    font-weight: 600;
    color: #333333; /* Dark gray for strong emphasis */
  }
  
  .asset-name,
  .secondary-id
  {
    font-size: smaller;
    font-weight: 400;
    padding-right: 10px;
    color: #666666; /* Lighter gray for readability */
  }
  