/* Task Report Component */
.task-report {
  margin-top: 20px;
}

/* Task List */
.task-list {
  margin-top: 10px;
}

/* Task */
.task {
  margin-bottom: 20px;
}

/* Optional: Add hover effect */
.task:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* Customer List */
.customer-list {
  margin-top: 10px;
}

/* Customer */
.customer {
  margin-top: 10px;
}

/* Table Styling */
table {
  border-collapse: collapse;
  border: 1px solid #a19797;
  border-radius: 5px;
}

th, td {
  padding: 1px;
  text-align: left;
  border: 0.5px solid #a19797;
  padding-inline: 2px;
  font-size: small;
}

th {
  background-color: #ff683a;
  color: #FFF;
}

td>p {
  font-weight: bold;
  background: #ff683a;
  margin-block: 0em;
  padding: 2px;
  color: #FFF
}

tr:nth-child(even) {
  background-color: #f0f0f0;
}


.customer-details {
  display: flex;
  flex-direction: row;
  margin-top: -2%;
}

.sub-table {
  /* margin-top: -1%; */
  display: inline-block;
  width: 100%;
}

.stretch-width {
  width: 50%;
  /* Or adjust as needed */
  align-content: flex-start;
}

.customer-details p {
  margin-right: 10px;
  /* Add some spacing between label-value pairs */
}

.sub-title-header-1 {
  background: #ff683a;
  text-align: center;
  color: #FFF;
}

.title-p {
  padding-block: 2px;
  padding-inline: 5px;
  background: #363166;
  color: #fff;
}

.title-p-1 {
  padding-block: 2px;
  padding-inline: 5px;
  background: #e0e2dd;
  color: #363166;
}

.section-title #clearCopy {
  float: right;
  cursor: pointer;
}